import { coreServices } from '@/services/coreServices';
import cookieManager from '../store/cookieManager';

export const accountServices = {
  /*
    Auth
  */
  async Login(data) {
    return coreServices.post('/users/login/', data, '');
  },
  logout() {
    return coreServices.post('/users/logout/', cookieManager.get('Authorization'));
  },
  setNewPasswordUpdated(formData) {
    const headers = coreServices.getRequestHeader();
    delete headers.Authorization;
    return coreServices.post('/users/update-expired-password/', formData, headers);
  },
  resetPassword(formData) {
    return coreServices.post('/users/forgot-password/', formData, '');
  },
  setNewPassword(formData) {
    const headers = coreServices.getRequestHeader();
    delete headers.Authorization;
    return coreServices.post('/users/forgot-password/confirm/', formData, headers);
  },
  changePassword(formData) {
    return coreServices.post('/users/update-password/', formData, coreServices.getRequestHeader());
  },
  /*
    Accounts
  */
  /** Get list of users */
  getUsers() {
    return coreServices.get('/users/', coreServices.getRequestHeader(), {});
  },
  /** Create a new user
   * @param {Object} data dictionary of the user
   */
  createUser(data) {
    return coreServices.post('/users/', data, coreServices.getRequestHeader());
  },
  /** Delete user
   * @param {int} userId User id to delete
   */
  deleteUser(userId) {
    return coreServices.delete(`/users/${userId}/`, coreServices.getRequestHeader());
  },
  /** Update a user
   * @param {Object} data dictionary of the user
   * @param {int} userId User id to update
   */
  updateUser(userId, data) {
    return coreServices.put(`/users/${userId}/`, data, coreServices.getRequestHeader());
  },
  getUserHistory() {
    return coreServices.get('/users/history/', coreServices.getRequestHeader(), {});
  },
  getUserApprovers(studyId) {
    return coreServices.get(`/users/approvers/?study_id=${studyId}`, coreServices.getRequestHeader(), {});
  },
  /** Get my user information */
  checkUserData() {
    return coreServices.get('/users/me/', coreServices.getRequestHeader(), {});
  },
  updateUsername(userId, data) {
    return coreServices.put(`/users/${userId}/update-username/`, data, coreServices.getRequestHeader());
  },
  /*
    Licences
  */
  getMyLicence() {
    return coreServices.get('/users/licences/me/', coreServices.getRequestHeader(), {});
  },
  getLicences() {
    return coreServices.get('/users/licences/', coreServices.getRequestHeader(), {});
  },
  createNewLicence(data) {
    return coreServices.post('/users/licences/', data, coreServices.getRequestHeader());
  },
  deleteLicence(licenceId) {
    return coreServices.delete(`/users/licences/${licenceId}/`, coreServices.getRequestHeader());
  },
  updateLicence(licenceId, data) {
    return coreServices.put(`/users/licences/${licenceId}/`, data, coreServices.getRequestHeader());
  },
  /*
    Account-App documentation
  */
  getDocumentationProfile() {
    return coreServices.get('/users/documentation/', coreServices.getRequestHeader(), {});
  },
  deleteDocumentationProfile(docId) {
    return coreServices.delete(`/users/documentation/${docId}/`, coreServices.getRequestHeader());
  },
  createDocumentationProfile(data) {
    const headers = coreServices.getRequestHeader({ 'Content-Type': 'multipart/form-data' });
    return coreServices.post('/users/documentation/', data, headers);
  },
  updateDocumentationProfile(docId, data) {
    const headers = coreServices.getRequestHeader({ 'Content-Type': 'multipart/form-data' });
    return coreServices.put(`/users/documentation/${docId}/`, data, headers);
  },
  getMyDocumentationProfile() {
    return coreServices.get('/users/documentation/my-docs/', coreServices.getRequestHeader(), {});
  },
  getDocumentationProfileUrl(documentId) {
    return coreServices.get(`/users/documentation/${documentId}/url/`, coreServices.getRequestHeader(), {});
  },
  markDocumentationProfileRead(documentId) {
    return coreServices.put(`/users/documentation/${documentId}/read/`, {}, coreServices.getRequestHeader());
  },
  downloadCertificate() {
    return coreServices.downloadGetBlob('/users/documentation/certificate/', coreServices.getRequestHeader());
  },
};
